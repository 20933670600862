import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GliderMaintenance } from '@app/core/interfaces/gliderMaintenance.interface';
import { Response } from '@app/core/interfaces/responses/response.interface';
import { typeAction } from '@app/shared/utils/enum';
import { environment } from '@src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class GliderMaintenanceService {
  private apiUrl = environment.urlMsFleet;
  private apiMicroSoftUrl = environment.urlMicroSoft;
  private subjectMaintenances = new Subject<GliderMaintenance>();
  public action: typeAction | undefined;

  constructor(private http: HttpClient) {}

  getMaintenanceList(): Observable<Response<GliderMaintenance[]>> {
    return this.http.get<Response<GliderMaintenance[]>>(`${this.apiUrl}/maintenances`);
  }

  getMaintenanceById(
    maintenanceId: number
  ): Observable<Response<GliderMaintenance>> {
    return this.http.get<Response<GliderMaintenance>>(
      `${this.apiUrl}/maintenances/${maintenanceId}`
    );
  }

  getMaintenanceTypes(): Observable<Response<GliderMaintenance>> {
    return this.http.get<Response<GliderMaintenance>>(
      `${this.apiUrl}/maintenance-types`
    );
  }

  createMaintenance(maintenance: any): Observable<Response<GliderMaintenance>> {
    return this.http.post<Response<GliderMaintenance>>(
      `${this.apiUrl}/maintenances`,
      maintenance
    );
  }

  updateMaintenance(
    maintenanceId: number,
    updateMaintenance: any
  ): Observable<Response<GliderMaintenance>> {
    return this.http.patch<Response<GliderMaintenance>>(
      `${this.apiUrl}/maintenances/${maintenanceId}`,
      updateMaintenance
    );
  }

  notifyDataSubject(
    maintenance?: GliderMaintenance,
    action?: typeAction
  ): void {
    this.action = action;
    this.subjectMaintenances.next(maintenance);
  }

  listenDataSubjectMaintenance(): Observable<GliderMaintenance> {
    return this.subjectMaintenances.asObservable();
  }

  getMaintenancesByGliderId(gliderId: number): Observable<GliderMaintenance[]> {
    return this.http.get<GliderMaintenance[]>(`${this.apiUrl}/maintenances/glider/${gliderId}`);
  }

  requestUnscheduledMaintenance(payload: {
    glider_name: string;
    user_email: string;
    maintenance_reasons: string;
    extra_notes: string;
  }): Observable<any> {
    return this.http.post(`${this.apiMicroSoftUrl}/ms-teams/send-unscheduled-maintenance-notification`, payload);
  }
}
