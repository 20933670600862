import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedVarService {

  private routerInfo: BehaviorSubject<string>
  constructor() {
    this.routerInfo = new BehaviorSubject<string>("");
    this.additionalState = {};
  }
  private additionalState: { [key: string]: any };

  setValue(newValue: any): void{
    this.routerInfo.next(newValue);
  }

  getValue(): Observable<string>{
    return this.routerInfo.asObservable();
  }

  setStateValue(key: string, value: any): void {
    if (!this.additionalState[key]) {
      this.additionalState[key] = new BehaviorSubject<any>(value);
    } else {
      this.additionalState[key].next(value);
    }
  }

  getStateValue<T>(key: string): Observable<T | null> {
    if (this.additionalState[key]) {
      return this.additionalState[key].asObservable();
    }
    return new BehaviorSubject<T | null>(null).asObservable();
  }
}
